const workshopCopy = {
  de: [
    {
      title1: 'Machs dir selbst…',
      title2: '...für Bürohengst & Bürostute',
      bodyParagraphs: [
        `Ihr seid ein eingespieltes Büroteam. Produktiv. Ehrgeizig. Wunde Gesäßbacken. Rücken die das letzte Mal gerade waren als für die Weihnachtsgans Platz gemacht werden musste. Regelmäßig wird zwischen Drucker und Kaffeemaschine nach einer Massage gesucht.`,
        `Ausgleich zu einem anstrengenden und unbewegten Arbeitsalltag?`,
        `- 404 not found -`,
        'Bewahrt den Überblick und kommt auf die gesunde Seite des Lebens.'
      ],
      bullets: [
        'Grundlagen des Trainings',
        'Rückengesundheit, Gewichtsabnahme, Stressabbau',
        'Mythbusting zum Thema Fitness, Gesundheit, Physiotherapie'
      ]
    },
    {
      title1: `Lass uns dir zur Hantel gehen...`,
      title2: '...für Fitnessbruder & Fitnessschwester',
      bodyParagraphs: [
        `Du schwitzt mittlerweile regelmäßig die Langhantel voll. Der Bizeps ist die ersten Zentimeter gewachsen. Doch du hast nicht genug? Dein Knie tut einfach jedes Mal weh? Du fragst dich jedes Mal ob vielleicht beim nächsten Kreuzheben deine Bandscheibe gegen den Spiegel fliegt?`,
        'Wir helfen dir dabei den Überblick im Fitnessdschungel zu bewahren.'
      ],
      bullets: [
        'Trainingsplanung Muskelaufbau',
        'Fettreduktion und Muskelaufbau',
        'Regeneration (Ernährung, Supplemente, Schlaf etc.)',
        'Techniktraining',
        'Vermeidung von Verletzungen und Selbsthilfe, wenn es schon zu spät ist'
      ]
    }
  ],
  en: [
    {
      title1: 'Do it yourself...',
      title2: '...for office stallion & office mare',
      bodyParagraphs: [
        `You're a well-oiled office team. Productive. Ambitious. Sore buttocks. Backs that were straight the last time you had to make space for the rest of the Christmas goose. Regularly looking for a massage between the printer and the coffee machine.`,
        `Balance to a demanding and sedentary schedule?`,
        `- 404 not found -`,
        'Stay on top and join the healthy side of life.'
      ],
      bullets: [
        'Basics of exercise',
        'Back health, weight loss, stress reduction',
        'Mythbusting about fitness, health, physical therapy'
      ]
    },
    {
      title1: `Let's make you less dumbell...`,
      title2: '...for fitness bro & fitness sis',
      bodyParagraphs: [
        `By now you are draining the barbell in sweat on a regular basis. Your biceps has grown its first few inches. But you don't have enough? Your knee aches every training? You ask yourself if your disc will fly against the mirror during the next deadlift attempt?`,
        'We help you to keep the overview in the fitness jungle.'
      ],
      bullets: [
        'Planning for hypertrophy',
        'Fat reduction and muscle building',
        'Regeneration (nutrition, supplements, sleep etc.)',
        'Technique training',
        'Prevention of injuries and self-help, when it is already too late'
      ]
    }
  ]
}

export default workshopCopy