import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const WorkshopHeader = ({ lang }) => {
  return(
    <div className="workshop-header-container">
      <StaticImage 
        src="../../images/workshop.jpg"
        alt=""
        style={{ position: 'absolute'}}
        imgStyle={{ objectPosition: '50% 34%'}}
        className="workshopBgImage"
      />
      <div className="overlay-grey"></div>
      <div><h1>{lang === 'de' ? 'VORTRÄGE' : 'WORKSHOPS'}</h1></div>
    </div>
  )
}

export default WorkshopHeader