import React from 'react'
import workshopCopy from '../../data/workshopCopy'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookOpenReader } from '@fortawesome/free-solid-svg-icons'


const Workshops = ({ lang }) => {
  const useCopy = workshopCopy[lang];
  const contactCopy = {
    de: 'Für weitere Informationen bitte Email an: ',
    en: 'For further information please contact us on our Email: '
  }
  return (
    <div>
      <div className="section-workshops light-gradient-bg">
        {useCopy.map((copy, i) => (
          <div key={i} className="workshop-container">
            <div className="workshop-titles">
              <p>{copy.title1}</p>
              <p>{copy.title2}</p>
            </div>
            <div className="workshop-paragraphs">
              {copy.bodyParagraphs.map((p, j) => (
                <p key={j}>{p}</p>
              ))}
            </div>
            <ul className="workshop-bullets">
              {copy.bullets.map((bullet, k) => (
                <li key={k}>
                  <FontAwesomeIcon icon={faBookOpenReader} className="icon-learn" />
                  <span>{bullet}</span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div className="workshop-contact">{contactCopy[lang]}<a href="mailto: office@bizepsmedizin.com" aria-label="Contact Us">office@bizepsmedizin.com</a></div>
    </div>
  )
}

export default Workshops