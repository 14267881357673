import React from 'react'
import Layout from '../components/Layout'
import WorkshopHeader from '../components/workshop/WorkshopHeader'
import Workshops from '../components/workshop/Workshops'
import '../styles/components/workshop.css'

const WorkshopPage = () => {
  return (
    <Layout pageTitle="BMI | Workshop">
      <WorkshopHeader lang />
      <Workshops lang />
    </Layout>
  )
}

export default WorkshopPage